import React from 'react'
import Navigation from '../components/Navigation'

import Illustration from '../images/illustrations/404.png'
import Button from '../components/Button'


function Page404() {
    return (
        <div className="penfolddesign-v4-gatsby page-404">
            <Navigation/>

            <div className="container-404">
                <img src={Illustration} alt=""/>
                <h1>Uh oh! Looks like you came to somewhere that doesn't exist.</h1>
                <Button
                
                    color="white"
                    to="/"
                    title="Back to home"
                
                />
            </div>
        </div>
    )
}

export default Page404
